<template>
  <div><a :href="$root.api.report_download + value" target="_blank">Открыть отчет</a></div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: {},
    },
  },
  methods: {

  },
};
</script>