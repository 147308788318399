<template>
  <div v-html="player(value)"></div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: {},
    },
  },
  methods: {
    player: function (value) {
      value = value.split(/(\\|\/)/g).pop().replace(/\.[^/.]+$/g, "");
      return (
        '<audio class="audio-player calls-audio" controls>' +
        '<source src="' + this.$root.recordUrl +
        value +
        '.mp3"' +
        ' type="audio/mpeg"/></audio>'
      );
    },
  },
};
</script>