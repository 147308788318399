const modules = [
    {
        path: 'users',
        name: 'users-table',
        meta: { auth: 100 },
        componentIndex: () => {
            return import('@/views/users/Index.vue')
        },
        componentForm: () => {
            return import('@/views/users/From.vue')
        }
    },
    {
        path: 'chat',
        name: 'chat',
        meta: { auth: 50 },
        componentIndex: () => {
            return import('@/views/ultrascope/chat/Index.vue')
        },
        componentForm: () => {
            return import('@/views/ultrascope/chat/Form.vue')
        }
    },
    {
        path: 'org',
        name: 'org',
        meta: { auth: 50 },
        componentIndex: () => {
            return import('@/views/ultrascope/org/Index.vue')
        },
        componentForm: () => {
            return import('@/views/ultrascope/org/Form.vue')
        }
    },
    {
        path: 'requests',
        name: 'requests',
        meta: { auth: 50 },
        componentIndex: () => {
            return import('@/views/ultrascope/requests/Index.vue')
        },
        componentForm: () => {
            return import('@/views/ultrascope/requests/Form.vue')
        }
    },
];

const routes = [
    {
        path: '/index',
        name: 'index',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/index/index.vue')
        }
    },
    {
        path: '/basic/:api/:view',
        name: 'basic',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/Basic/Index.vue')
        }
    },
    {
        path: '/stat/:api/:view',
        name: 'stat',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/ultrascope/stat/Index.vue')
        }
    },
    {
        path: '/messages/',
        name: 'messages',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/ultrascope/messages/Index.vue')
        }
    },
];

for (let i = 0; i < modules.length; i++) {
    const module = modules[i];
    routes.push(
        {
            path: "/" + module.path + "/:api/:view",
            name: module.name,
            meta: module.meta,
            component: module.componentIndex
        }
    );
    routes.push(
        {
            path: "/" + module.path + "/:api/:view/create",
            name: module.name + "-create",
            meta: module.meta,
            component: module.componentForm
        }
    );
    routes.push(
        {
            path: "/" + module.path + "/:api/:view/update/:id",
            name: module.name + "-update",
            meta: module.meta,
            component: module.componentForm
        }
    );
}

export default {
    routes: routes
}