import { render, staticRenderFns } from "./MaterialTestimony.vue?vue&type=template&id=da04c898&"
import script from "./MaterialTestimony.vue?vue&type=script&lang=js&"
export * from "./MaterialTestimony.vue?vue&type=script&lang=js&"
import style0 from "./MaterialTestimony.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../www/ultrascope_bot/ultrascope_bot_lk/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports