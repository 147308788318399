<template>
  <div>
    <span v-for="(item, i) in items" :key="i">
      {{ item }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: {},
    },
  },
  computed: {
    items() {
      let res;
      try {
        res = JSON.parse(this.value);
      } catch (error) {
        res = this.value;
      }
      return res;
    },
  },
};
</script>