import axios from 'axios'
import api from '@/config/api'

const state = {
  data: {},
}

const getters = {
  get: state => state.data
}

const actions = {
  fitch: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios.get(api.config)
        .then(response => {
          commit('set', response.data)
          resolve();
        })
        .catch(error => { console.log(error) })
    })
  }
}

const mutations = {
  set: (state, resp) => {
    state.data = resp
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
