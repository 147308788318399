var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"f-table-col",style:({ width: _vm.width + 'px' })},[(
      [
        'id',
        'string',
        'ref-table',
        'login',
        'number',
        'phones',
        'sparams'
      ].includes(_vm.model.type)
    )?_c('a-input-table-search-string',{attrs:{"value":_vm.values[_vm.model.name]},on:{"input":_vm.onInputElement}}):(['select'].includes(_vm.model.type))?_c('a-input-table-search-select',{attrs:{"value":_vm.values[_vm.model.name],"model":_vm.model},on:{"input":_vm.onInputElement}}):(_vm.model.type == 'ref' && _vm.model.input == 'select')?_c('a-input-table-search-ref-select',{attrs:{"value":_vm.values[_vm.model.name + '_id'],"model":_vm.model},on:{"input":function($event){_vm.values[_vm.model.name + '_id'] = $event; _vm.$emit('input', _vm.values)}}}):(
      [,
        'date',
        'datetime' ].includes(_vm.model.type)
    )?_c('a-input-table-search-datetime',{attrs:{"value":_vm.values[_vm.model.name]},on:{"input":_vm.onInputElement}}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }