<template>
  <div>
    <v-chip class="ma-2" :color="element.color" :title="element.title">
      {{ element.text }}
    </v-chip>
  </div>
</template>

<script>
import modelOptions from "@/share/components/libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: Object,
    model: {
      type: Object,
      default: {},
    },
  },
  computed: {
    element() {
      if (this.value == 1 && this.values.eventon) {
        const d = new Date(this.values.eventon).getTime();
        const dCurr = Date.now();
        if (dCurr - d > 0) {
          return {
            color: "red",
            text: "Просрочена",
            title: "Задача просрочена",
          };
        } else {
          return {
            color: "yellow darken-3",
            text: this.getTextByValue(this.value, this.model),
            title: this.getTextByValue(this.value, this.model),
          };
        }
      } else if (this.value == 2) {
        return {
          color: "green",
          text: "Выполнена",
          title: "Задача выполнена",
        };
      }
      return {
        color: "primary",
        text: this.getTextByValue(this.value, this.model),
        title: this.getTextByValue(this.value, this.model),
      };
    },
  },
  methods: {},
};
</script>