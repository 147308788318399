import { render, staticRenderFns } from "./color.vue?vue&type=template&id=11a9d732&"
import script from "./color.vue?vue&type=script&lang=js&"
export * from "./color.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../www/ultrascope_bot/ultrascope_bot_lk/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports