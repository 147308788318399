<template>
  <div>
      <router-link :to="{name: 'get-car-phone', params: {phone: value}}">{{ value }}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: {},
    },
  }
};
</script>