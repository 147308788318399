// const BASE_URL = 'http://api.ultrascope.callbaza.ru/api/';


export default {
    baseUrl: BASE_URL,
    config: BASE_URL + 'config',
    token_login: BASE_URL + 'signup/get_token',
    set_profile: BASE_URL + 'signup/set_profile',
    get_profile: BASE_URL + 'signup/get_profile',
    login_exists: BASE_URL + 'signup/login_exists',
    activation: BASE_URL + 'signup/activation',
    file_upload: BASE_URL + 'file/upload',
    file_download: BASE_URL + 'file/download',
    image_upload: BASE_URL + 'image/upload',
    image_download: BASE_URL + 'image/download',
    basic: BASE_URL + 'basic',

    report_download: BASE_URL + 'ultrascope/report/download/',
    users: BASE_URL + 'users/users',
    chat: BASE_URL + 'ultrascope/chat/chat',
    stat: BASE_URL + 'stat/stat/stat',
    org: BASE_URL + 'ultrascope/org/org',
    requests: BASE_URL + 'ultrascope/requests/requests',
    
}